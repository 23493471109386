import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import FormControl from '@material-ui/core/FormControl'
import floatingPlanActions from 'camera-module/camera/core/floating-plan/core/floatingPlanActions'
import cameraActions from 'camera-module/camera/core/cameraActions'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import { useHistory } from 'react-router-dom'

const FloatingPlansButton = ({ onClose, selectedPlan, camera, setOpen, match, isActivation }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const subscription = {
    cameraId: camera?.id,
    subscriptionId: selectedPlan?.id,
  }
  const onSubmit = async () => {
    floatingPlanActions.assignSubscription(subscription)
      .then(() => {
        if (isActivation) { history.push('/confirm-activation') } else {
          Promise.all([
            dispatch(cameraActions.get(match.params.id)),
            dispatch(floatingPlanActions.fetchSubscriptions()),
          ])
          setOpen(false)
        }
      })
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <CButton onClick={() => onSubmit()} disabled={!selectedPlan} color="primary" variety="primary">
        { t('app:promo_code.apply_button') }
      </CButton>
    </FormControl>
  )
}

export default FloatingPlansButton
