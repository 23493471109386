import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { VoskerInputStyles } from 'spypoint/src/components/buttons/VoskerInputField'
import { useTranslation } from 'react-i18next'
import VoskerSecondaryButton from 'spypoint/src/components/buttons/VoskerSecondaryButton'
import CButton from 'storybook-component-module/src/components/buttons/CButton'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import Sad from 'spypoint/public/images/sad.png'
import PromotionsIcon from 'assets/icons/navigation/PromotionsIcon'
import ProductsIcon from 'assets/icons/navigation/ProductsIcon'
import TipsIcon from 'assets/icons/navigation/TipsIcon'
import MoreIcon from 'assets/icons/navigation/MoreIcon'
import NewsLetter from 'spypoint/public/images/NewsLetter.png'
import { Box } from '@material-ui/core'
import FloatingPlansButton from './FloatingPlansButton'
import FloatingPlansSelect from './FloatingPlansSelect'

const useStyles = makeStyles(theme => ({
  dialog: {
    width: '432px',
    padding: '32px',
    backgroundColor: '#1F1E1E',
    margin: 'auto',
    borderRadius: 0,
  },
  imageHelper: {
    textAlign: 'center',
    marginTop: '6px',
  },
  promotions: {
    backgroundColor: '#313030',
    borderRadius: 6,
    marginBottom: '32px',
  },
  label: {
    marginTop: 10,
  },
  link: {
    color: `${theme.palette.status?.info}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      marginBottom: '0.25rem',
    },
  },
  textContent: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 500,
    fontFamily: 'oswald-normal',
    marginBottom: '24px',
    color: theme.palette.text.primary,
  },
  text: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'ibm-plex-sans-regular',
    marginBottom: '16px',
    color: theme.palette.text.primary,
  },
  title: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'ibm-plex-sans-regular',
    color: theme.palette.text.primary,
  },
  subTitle: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'ibm-plex-sans-regular',
    marginBottom: '35px',
    color: theme.palette.text.primary,
  },
  content: {
    overflowY: 'hidden !important',
  },
  contentSubtitle: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'ibm-plex-sans-regular',
    color: theme.palette.text.primary,
  },
}))

const FloatingPlansDialog = ({ onClose, setOpen, setSelectedPlan, selectedPlan, floatingPlans, camera, match }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <Dialog
      open
      onClose={onClose}
      disableBackdropClick
      PaperProps={{
        className: classes.dialog,
      }}
    >
      <div className={classes.textContent}>
        { t('floating_plans.title') }
      </div>
      <div style={{ marginRight: '25px' }}>
        <FloatingPlansSelect setSelectedPlan={setSelectedPlan} selectedPlan={selectedPlan} floatingPlans={floatingPlans} />
        { selectedPlan && (
          <><div className={classes.title}>{ selectedPlan?.name }</div>
            <div style={{
              display: 'flex',
              height: '100%',
              marginTop: 15,
              justifyContent: 'space-between',
            }}
            >
              <div style={{
                display: 'flex',
                height: 'auto',
              }}
              >
                <div>{ t('device') }</div>
              </div>
              <div className={classes.contentSubtitle}>{ camera?.config?.name }</div>
            </div>
            <div style={{
              display: 'flex',
              height: '100%',
              marginTop: 15,
              justifyContent: 'space-between',
            }}
            >
              <div style={{
                display: 'flex',
                height: 'auto',
              }}
              >
                <div>{ t('billing.bill.payment_frequency') }</div>
              </div>
              <div className={classes.contentSubtitle}>{ selectedPlan?.frequency === 'month' ? t('app:checkout_process.photo_transmission_plan.monthly') : t('app:checkout_process.photo_transmission_plan.monthly') }</div>
            </div>
            <div style={{
              display: 'flex',
              height: '100%',
              marginTop: 15,
              marginBottom: 15,
              justifyContent: 'space-between',
            }}
            >
              <div style={{
                display: 'flex',
                height: 'auto',
              }}
              >
                <div>{ t('billing.photos_per_month') }</div>
              </div>
              <div className={classes.contentSubtitle}>{ selectedPlan?.name === 'Premium' ? t('unlimited') : selectedPlan?.capabilities?.photoLimit }</div>
            </div>
          </>
        ) }
      </div>
      <div style={{
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'ibm-plex-sans-regular',
        color: '#B1AFAF',
        marginRight: '10px',
        marginBottom: 15,
      }}
      >
        <span style={{
          textAlign: 'left',
          fontSize: '12px',
          fontWeight: 400,
          fontFamily: 'ibm-plex-sans-regular',
          color: '#FBF9F9',
          marginRight: '10px',
          marginBottom: 15,
        }}
        >{ t('floating_plans.note') }
        </span>{ t('floating_plans.footer') }
      </div>
      { breakpoint
        ? (
          <Grid container justifyContent="flex-end">
            <Grid xs={12} item style={{ marginBottom: '24px' }}>
              <CButton color="primary" onClick={() => setOpen(false)} variety="secondary" style={{ width: '100%' }}>
                { t('button.cancel') }
              </CButton>
            </Grid>
            <Grid xs={12} item>
              <FloatingPlansButton camera={camera} setOpen={setOpen} selectedPlan={selectedPlan} onClose={onClose} match={match} />
            </Grid>
          </Grid>
          )
        : (
          <Grid container justifyContent="flex-end">
            <Grid item style={{ marginRight: '24px' }}>
              <CButton color="primary" onClick={() => setOpen(false)} variety="secondary" style={{ width: '100%' }}>
                { t('button.cancel') }
              </CButton>
            </Grid>
            <Grid item>
              <FloatingPlansButton camera={camera} setOpen={setOpen} selectedPlan={selectedPlan} onClose={onClose} match={match} />
            </Grid>
          </Grid>
          ) }
    </Dialog>
  )
}

export default FloatingPlansDialog
