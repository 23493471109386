import floatingPlanApi from '../api/floatingPlanApi'

const setSubscriptions = subscription => ({ type: 'SET_SUBSCRIPTIONS', subscription })

const fetchSubscriptions = () => dispatch => {
  return floatingPlanApi.getSubscriptions()
    .then(subscription => {
      dispatch(setSubscriptions(subscription))
    })
}

const assignSubscription = (subscription) => {
  return floatingPlanApi.assignSubscriptions(subscription)
}

const floatingPlanActions = {
  setSubscriptions,
  fetchSubscriptions,
  assignSubscription,
}

export default floatingPlanActions
