import restapi from 'shared-module/api/restapi'
import buildUser, { deleteUserCompleted } from 'user-module/user/api/userAntiCorruptionLayer'
import handleError from 'shared-module/api/apiError'
import buildUserUpdate from 'user-module/user/api/userUpdateAntiCorruptionLayer'
import buildScoutingPackage from 'user-module/user/api/scoutingPackageBuilder'
import buildHdPackage from 'user-module/user/api/hdPackageBuilder'
import buildUserClubMembership from 'spypoint/src/user-module/club-membership/api/userClubMembershipBuilder'

const getById = userId => restapi
  .get(`/api/v3/user/${userId}`)
  .then(user => buildUser(user.data))
  .catch(handleError)

const register = user => restapi
  .post('/api/v3/user', user)
  .then(res => res)

const login = credentials => restapi
  .post('/api/v3/user/login', credentials)

const logout = ({ deviceID, commerceRefresh, commerceToken }) => {
  const payload = { deviceID }
  const options = {
    withCredentials: true,
  }
  if (commerceRefresh && commerceToken) {
    payload.commerceRefresh = commerceRefresh
    options.headers = {
      'x-commerce-token': commerceToken,
    }
  }
  return restapi.post('/api/v3/user/logout', payload, options)
}

const update = user => restapi
  .put(`/api/v3/user/${user.id}`, buildUserUpdate(user))
  .catch(handleError)

const updateExtended = user => restapi
  .put(`/api/v3/user/${user.id}`, { ...user })
  .catch(handleError)

const resetPassword = username => restapi
  .post('/api/v3/user/password', { email: username })

const updatePassword = (password, newPassword) => restapi
  .put('/api/v3/user/password', { current: password, new: newPassword })
  .catch(handleError)

const changePassword = (resetToken, newPassword) => restapi
  .post('/api/v3/user/password/form', { resetKey: resetToken, password: newPassword })

const validatePassword = password => restapi
  .post('/api/v3/user/permissions/account', { password })

const deleteUser = () => restapi
  .delete('/api/v3/user')
  .then(response => deleteUserCompleted(response.data))

const getScountingPackage = () => restapi
  .get('/api/v3/storage/subscription')
  .then(subscriptions => buildScoutingPackage(subscriptions.data))
  .catch(handleError)

const getClubMembership = () => restapi
  .get('/api/v3/storage/subscription')
  .then(subscriptions => buildUserClubMembership(buildScoutingPackage(subscriptions.data)))
  .catch(handleError)

const getHdPackage = () => restapi
  .get('/api/v3/hd')
  .then((response) => buildHdPackage(response.data))
  .catch(handleError)

const userApi = {
  getById: getById,
  deleteUser: deleteUser,
  register: register,
  login: login,
  logout: logout,
  update: update,
  updateExtended: updateExtended,
  resetPassword: resetPassword,
  updatePassword: updatePassword,
  changePassword: changePassword,
  getScountingPackage: getScountingPackage,
  getClubMembership: getClubMembership,
  getHdPackage: getHdPackage,
  validatePassword: validatePassword,
}
export default userApi
