import React from 'react'
import Box from '@mui/material/Box'
import { makeStyles } from '@material-ui/core/styles'
import SyncPendingIcon from 'assets/icons/SyncPendingIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import WarningIcon from 'assets/icons/WarningIcon'
import LinkIcon from 'assets/icons/LinkIcon'
import WarningBox from 'camera-module/camera/ui/camera-sharing-page/WarningBox/WarningBox'

const useStyles = makeStyles((theme) => ({
  warning: {
    display: 'flex',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    justifyContent: 'center',
  },
  warningWrapper: {
    margin: '20px',
    flexGrow: '2',
    maxWidth: '1000px',
  },
  WarningBox: {
    marginTop: '170px',
  },
}))
const FloatingPlanBanner = ({ setOpen }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <div onClick={() => setOpen(true)} className={classes.warning}>
      <div className={classes.warningWrapper} elevation={1}>
        <div className={classes.WarningBox} elevation={1}>
          <WarningBox
            Icon={WarningIcon}
            date={t('floating_plans_banner.text')}
            link={t('floating_plans_banner.link')}
            LinkIcon={LinkIcon}
          />
        </div>
      </div>
    </div>
  )
}

export default FloatingPlanBanner
