import jwtDecode from 'jwt-decode'
import { eCommerceRefreshTokenID, eCommerceTokenID } from 'ecommerce-module/core/eCommerce.selectors'

const tokenId = 'token'
const refreshId = 'refresh'
const deviceId = 'device'
const basketTokenID = 'basketId'

const keysToRemove = [tokenId, eCommerceTokenID, eCommerceRefreshTokenID, basketTokenID]

const get = () => localStorage.getItem(tokenId)
const getRefresh = () => localStorage.getItem(refreshId)
const getCommerceRefresh = () => localStorage.getItem(eCommerceRefreshTokenID)
const getCommerceToken = () => localStorage.getItem(eCommerceTokenID)
const getDevice = () => localStorage.getItem(deviceId)
const setDevice = device => localStorage.setItem(deviceId, device)

const set = (token) => localStorage.setItem(tokenId, token)

const setRefresh = refresh => localStorage.setItem(refreshId, refresh)
const remove = () => keysToRemove.forEach(key => localStorage.removeItem(key))
const decodeUserId = () => jwtDecode(get()).user._id

export default {
  get,
  set,
  remove,
  decodeUserId,
  setRefresh,
  getRefresh,
  getDevice,
  setDevice,
  getCommerceRefresh,
  getCommerceToken,
}
