import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import WarningIcon from 'assets/icons/navigation/WarningIcon'
import makeStyles from '@material-ui/core/styles/makeStyles'
import useCameraActions from 'camera-module/camera/core/useCameraActions'
import usePurpose from 'camera-module/camera/core/usePurpose'

const useStyles = makeStyles(theme => ({
  icon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
    color: theme.palette.status?.error || theme.palette.error?.main,
  },
}))

const FloatingPlansSelect = ({ historyControl, setHistoryControl, disabled, floatingPlans, setSelectedPlan, selectedPlan }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const uniquePlans = Object.values(
    floatingPlans.reduce((acc, plan) => {
      const key = `${plan.frequency}-${plan.name}` // Clé unique combinant frequency + name
      if (!acc[key]) {
        acc[key] = plan // Garde le premier élément trouvé
      }
      return acc
    }, {}),
  )

  useEffect(() => {
    if (uniquePlans?.length === 1) setSelectedPlan(uniquePlans[0])
  }, [uniquePlans])

  const renderfloatingPlanTypesRow = (floatingPlan) => {
    return (
      <MenuItem value={floatingPlan} key={floatingPlan.id}>
        { t(`floating_plans.plans.${floatingPlan.name}_${floatingPlan.frequency}`) }
      </MenuItem>
    )
  }

  const renderPlansRows = () => {
    return uniquePlans?.map((floatingPlan) => renderfloatingPlanTypesRow(floatingPlan))
  }

  return (
    <FormControl variant="outlined" required fullWidth margin="dense">
      <InputLabel>{ t('floating_plans.input') }</InputLabel>
      <Select
        disabled={disabled}
        style={{ marginBottom: 18 }}
        value={uniquePlans.length === 1 ? uniquePlans[0] : selectedPlan}
        label={t('floating_plans.input')}
        onChange={event => setSelectedPlan(event.target.value)}
      >
        { renderPlansRows() }
      </Select>
    </FormControl>
  )
}

export default FloatingPlansSelect
