import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import { makeStyles } from '@material-ui/core/styles'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import useUser from 'user-module/user/core/useUser'
import { fetchProductItems } from 'ecommerce-module/core/eCommerce.actions'
import { getProductItemsSelector } from 'ecommerce-module/core/eCommerce.selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useECommerceFlag } from '../../../../ecommerce-module/hooks/UseEcommerceFlag'
import useQueryParams from 'shared-module/url/useQueryParams'

const useStyles = makeStyles(theme => ({
  badge: {
    top: 3,
    right: 2,
    fontFamily: theme.typography.h1.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    padding: '0 4px',
  },
}))

const SpypointCartButton = () => {
  const classes = useStyles()
  const { cartQuantity, navigateToCart } = useCart()
  const eCommerceEnabled = useECommerceFlag()
  const user = useUser()
  const displayCartItemNumber = () => cartQuantity < 10 ? cartQuantity : '..'

  const dispatch = useDispatch()
  const queryParams = useQueryParams()
  const productItems = useSelector(getProductItemsSelector)

  const getTotalQuantity = (items) => {
    let totalQuantity = 0
    items?.forEach(item => {
      totalQuantity += item.quantity
    })
    return totalQuantity
  }

  useEffect(() => {
    if (eCommerceEnabled && !productItems?.productItems) {
      dispatch(fetchProductItems(queryParams))
    }
  }, [])

  if (!user.cartEnabled) {
    return null
  }

  if (eCommerceEnabled) {
    return (
      <IconButton
        onClick={navigateToCart}
        aria-label="cart"
        data-testid="sp-cart-button"
      >
        { productItems.productItems?.length > 0
          ? (
            <Badge
              badgeContent={getTotalQuantity(productItems?.productItems)}
              color="primary"
              classes={{ anchorOriginTopRightRectangular: classes.badge }}
              data-testid="sp-cart-button-count"
            >
              <ShoppingCart />
            </Badge>
            )
          : <ShoppingCart /> }
      </IconButton>
    )
  }

  return (
    <IconButton
      onClick={navigateToCart}
      aria-label="cart"
      data-testid="sp-cart-button"
    >
      { cartQuantity > 0
        ? (
          <Badge
            badgeContent={displayCartItemNumber()}
            color="primary"
            classes={{ anchorOriginTopRightRectangular: classes.badge }}
            data-testid="sp-cart-button-count"
          >
            <ShoppingCart />
          </Badge>
          )
        : <ShoppingCart /> }
    </IconButton>
  )
}

export default SpypointCartButton
