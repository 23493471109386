import apm from 'shared-module/monitoring/apm'
import userApi from 'user-module/user/api/userApi'
import jwtToken from 'shared-module/token/jwtToken.js'
import { v4 as uuid } from 'uuid'

import appResetState from 'shared-module/app-reset/appResetState'
import messageActions from 'shared-module/message/messageActions'

import { FETCH_USER, SET_USER } from 'user-module/user/core/userActionTypes'
import cartActions from 'spypoint/src/checkout-module/order/core/cart.actions'
import notificationsActions from 'spypoint/src/notifications-module/core/notifications.actions'
import userClubMembershipState from 'spypoint/src/user-module/club-membership/core/userClubMembershipState'
import userHdPackageState from '../../hd-package/core/userHdPackageState'

const getAndSet = (userId) => dispatch =>
  userApi.getById(userId)
    .then(user => {
      apm.setUserContext({ id: user.id, email: user.email })
      if (user.cartEnabled) dispatch(cartActions.fetchCart(user))
      dispatch(notificationsActions.getAllNotifications())
      return Promise.all([
        dispatch(userClubMembershipState.get()),
        dispatch(userHdPackageState.get()),
      ])
        .then(() => dispatch(set(user)))
    })

const get = (userId) => dispatch => {
  dispatch(({ type: FETCH_USER }))

  return dispatch(getAndSet(userId))
}

const set = user => ({ type: SET_USER, user })

// Utility method
const decodeUserId = dispatch => {
  try {
    return jwtToken.decodeUserId()
  } catch {
    dispatch(userActions.logout())
  }
}

const reload = userId => dispatch =>
  userApi
    .getById(userId)
    .then(user => dispatch(set(user)))

const login = (token, useActions) => dispatch => {
  jwtToken.set(token)

  const userId = decodeUserId(dispatch)

  return dispatch(get(userId, useActions))
}

const register = user => dispatch => {
  const deviceID = jwtToken.getDevice() !== null ? jwtToken.getDevice() : uuid()
  jwtToken.setDevice(deviceID)

  return userApi.register({ ...user, deviceID })
    .then(response => dispatch(login(response.data.token, response.data.refresh)))
}

const loginUser = (username, password) => dispatch => {
  const deviceID = jwtToken.getDevice() !== null ? jwtToken.getDevice() : uuid()
  jwtToken.setDevice(deviceID)

  return userApi.login({ username, password, deviceID })
    .then(response => dispatch(login(response.data.token, response.data.refresh)))
}

const logout = () => (dispatch) => {
  const deviceID = jwtToken.getDevice()
  const commerceRefresh = jwtToken.getCommerceRefresh()
  const commerceToken = jwtToken.getCommerceToken()
  const cleanUpLocalstorage = () => {
    jwtToken.remove()
    dispatch(appResetState.reset())
  }
  return userApi
    .logout({ deviceID, commerceRefresh, commerceToken })
    .then(cleanUpLocalstorage)
}

const deleteUser = () => dispatch =>
  userApi
    .deleteUser()
    .catch(() => dispatch(messageActions.showError('app:account.management.delete.message.error')))

const updatePassword = (password, newPassword) => (dispatch) =>
  userApi
    .updatePassword(password, newPassword)
    .then(() => dispatch(messageActions.showSuccess('app:account.profile.password.message.success')))
    .catch(() => dispatch(messageActions.showError('app:account.profile.password.message.error')))

const validatePassword = password => dispatch =>
  userApi
    .validatePassword(password)
    .then(response => {
      jwtToken.set(response.data.token)
      return dispatch(messageActions.showSuccess('app:account.management.password.message.success'))
    })
    .catch(() => dispatch(messageActions.showError('app:account.management.password.message.error')))

const userActions =
  {
    get: get,
    set: set,
    reload: reload,
    register: register,
    login: login,
    loginUser: loginUser,
    logout: logout,
    deleteUser: deleteUser,
    updatePassword: updatePassword,
    validatePassword: validatePassword,
  }
export default userActions
