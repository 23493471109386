import restapi from 'shared-module/api/restapi'

const getSubscriptions = () => {
  return restapi.get('/api/v3/subscription/all')
    .catch(() => [])
}

const assignSubscriptions = (subscription) => {
  return restapi.patch('/api/v3/subscription/assign', subscription)
    .catch(() => [])
}

const floatingPlanApi = {
  getSubscriptions,
  assignSubscriptions,
}

export default floatingPlanApi
