import { useDispatch, useSelector } from 'react-redux'
import cameraState from 'camera-module/camera/core/cameraState'
import cameraActions from 'camera-module/camera/core/cameraActions'

const useCamera = cameraId => {
  const state = useSelector(state => state.selectedCamera)
  const dispatch = useDispatch()

  if (cameraId && !cameraState.isReady(state, cameraId)) {
    dispatch(cameraActions.get(cameraId))
  }

  return state.camera
}

export default useCamera
