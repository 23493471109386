import { useEffect } from 'react'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import AppBarContainer from 'shared-module/components/app-bar/AppBarContainer'
import useUser from 'user-module/user/core/useUser'
import { useUserClubMembership } from 'spypoint/src/user-module/club-membership/core/useUserClubMembership'
import { useCart } from '../../core/cart.hooks'
import CartTotalLine from '../cart-lines/CartTotalLine'
import CartSavingsLine from '../checkout-page/checkout-lines/CartSavingsLine'
import CartContent from './CartContent'
import CartSection from './CartSection'
import DirectPurchaseWrapper from './DirectPurchaseWrapper'
import EmptyCart from './EmptyCart'
import NewCartButtons from './NewCartButtons'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProductItems, deleteItemFromBasket } from 'ecommerce-module/core/eCommerce.actions'
import { getBasketSelector, getProductItemsSelector } from 'ecommerce-module/core/eCommerce.selectors'
import { useECommerceFlag } from '../../../../ecommerce-module/hooks/UseEcommerceFlag'
import Amount from 'shared-module/components/amount/Amount'
import CartDivider from 'spypoint/src/checkout-module/order/ui/cart-lines/CartDivider'
import LineTitle from 'spypoint/src/checkout-module/order/ui/cart-lines/LineTitle'
import RemoveCartItemButton from 'spypoint/src/checkout-module/order/ui/cart-page/RemoveCartItemButton'
import { Grid } from '@material-ui/core'
import { useActivatedCamera } from 'camera-module/new-activation/core/activatedCamera.hook'

const NewCart = ({ setStep, itemId }) => {
  const { t } = useTranslation()
  const {
    deleteFromCart,
    cartItems,
    cartItemsCount,
    isUnavailableItemInCart,
  } = useCart()
  const userClubMembership = useUserClubMembership()
  const user = useUser()
  const eCommerceEnabled = useECommerceFlag()
  const activatedCamera = useActivatedCamera()
  const dispatch = useDispatch()
  const { basketId, productItems } = useSelector(getProductItemsSelector)
  const { basket } = useSelector(getBasketSelector)

  useEffect(() => {
    if (eCommerceEnabled) {
      dispatch(fetchProductItems())
    }
  }, [eCommerceEnabled, dispatch])

  const handleDeleteItem = async (basketId, itemId) => {
    try {
      productItems.filter(item => item.itemId !== itemId)
      dispatch(deleteItemFromBasket(basketId, itemId))
    } catch (err) {
      console.error(err)
    }
  }

  return user.cartEnabled
    ? (
      <DirectPurchaseWrapper isNewUi clubMembership={userClubMembership}>
        <AppBarContainer Icon={ShoppingCartIcon} title={t('menu.cart')} />
        { eCommerceEnabled
          ? (
              !productItems || productItems.length === 0
                ? <EmptyCart isNewUi />
                : (
                  <>
                    { basketId && (
                      <CartSection isNewUi>
                        { productItems?.map((product, index) => (
                          <Grid key={product.productId || index}>
                            <Grid container justifyContent="space-between" spacing={3}>
                              <LineTitle full bold>
                                { t('app:checkout_process.photo_transmission_plan.title', { planType: product.productName }) }
                              </LineTitle>
                              <LineTitle>{ t('app:checkout_process.device') }</LineTitle>
                              <LineTitle align="right" overflow="hidden" bold> { product.c_cameraName }</LineTitle>
                            </Grid>
                            <Grid container justifyContent="space-between" spacing={3}>
                              <LineTitle bold>{ t('app:checkout_process.price') }</LineTitle>
                              <LineTitle bold align="right">
                                <Amount value={product.price} currencyCode={user.currencyCode} />
                              </LineTitle>
                            </Grid>
                            <RemoveCartItemButton
                              productId={product.itemId}
                              onDelete={() => {
                                handleDeleteItem(basketId, product.itemId)
                              }}
                            />
                            <CartDivider />
                          </Grid>
                        ),
                        ) }

                        { basket && (
                          <Grid container justifyContent="space-between" spacing={3}>
                            <LineTitle bold>{ t('app:checkout_process.cart.total_before_tax').toLocaleUpperCase() }</LineTitle>
                            <LineTitle align="right" bold>
                              <Amount value={basket?.productTotal} currencyCode={user.currencyCode} />
                            </LineTitle>
                          </Grid>
                        ) }

                        <CartSavingsLine />
                      </CartSection>
                    ) }
                    <NewCartButtons isNewUi setStep={setStep} itemId={itemId} deleteFromCart={deleteFromCart} handleDeleteItem={handleDeleteItem} isUnavailableItemInCart={isUnavailableItemInCart} redirect={window.location.origin + `/confirm-activation/${activatedCamera.id}`} />
                  </>
                  )
            )
          : (
              cartItemsCount === 0
                ? <EmptyCart isNewUi />
                : (
                  <>
                    <CartSection isNewUi>
                      <CartContent isNewUi products={cartItems.products} setStep={setStep} />
                      <CartTotalLine />
                      <CartSavingsLine />
                    </CartSection>
                    <NewCartButtons isNewUi setStep={setStep} itemId={itemId} deleteFromCart={deleteFromCart} handleDeleteItem={handleDeleteItem} isUnavailableItemInCart={isUnavailableItemInCart} redirect={window.location.origin + `/confirm-activation/${activatedCamera.id}`} />
                  </>
                  )
            ) }
      </DirectPurchaseWrapper>
      )
    : <Redirect to="/" />
}

export default NewCart
