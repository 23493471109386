import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  icon: {
    width: '1rem',
    top: '0.375rem',
    position: 'relative',
    marginRight: '0.313rem',
    color: theme.palette.status?.error || theme.palette.error?.main,
  },
}))

const FloatingPlansActivationSelect = ({ disabled, floatingPlans, setSelectedPlan, selectedPlan }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const uniquePlans = Object.values(
    floatingPlans.reduce((acc, plan) => {
      const key = `${plan.frequency}-${plan.name}` // Clé unique combinant frequency + name
      if (!acc[key]) {
        acc[key] = plan // Garde le premier élément trouvé
      }
      return acc
    }, {}),
  )
  const items = uniquePlans.concat([{
    id: '0',
    name: 'New',
    frequency: 'annual',
  }])

  const renderfloatingPlanTypesRow = (floatingPlan) => {
    return (
      <MenuItem value={floatingPlan.id} key={floatingPlan.id}>
        { t(`floating_plans.plans.${floatingPlan.name}_${floatingPlan.frequency}`) }
      </MenuItem>
    )
  }

  const renderPlansRows = () => {
    return items?.map((floatingPlan) => renderfloatingPlanTypesRow(floatingPlan))
  }

  return (
    <FormControl variant="outlined" required fullWidth margin="dense">
      <InputLabel>{ t('floating_plans.placeholder') }</InputLabel>
      <Select
        disabled={disabled}
        style={{ width: '350px' }}
        value={selectedPlan?.id || ''}
        label={t('floating_plans.placeholder')}
        onChange={event => {
          const selected = items.find(plan => plan.id === event.target.value)
          setSelectedPlan(selected) // Met à jour avec l'objet entier
        }}
      >
        { renderPlansRows() }
      </Select>
    </FormControl>
  )
}

export default FloatingPlansActivationSelect
