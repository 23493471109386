import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material'
import FloatingPlansButton from 'checkout-module/checkout/ui/transmission-plans-page/FloatingPlansButton'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  label: {
    marginTop: 10,
  },
  link: {
    color: `${theme.palette.status?.info}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      marginBottom: '0.25rem',
    },
  },
  textContent: {
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 500,
    fontFamily: 'oswald-normal',
    marginBottom: ({ isNewUi }) => isNewUi ? '16px' : '24px',
    color: theme.palette.text.primary,
  },
  text: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'ibm-plex-sans-regular',
    marginBottom: '16px',
    color: theme.palette.text.primary,
  },
  title: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 700,
    fontFamily: 'ibm-plex-sans-regular',
    color: theme.palette.text.primary,
  },
  subTitle: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 400,
    fontFamily: 'ibm-plex-sans-regular',
    marginBottom: '35px',
    color: theme.palette.text.primary,
  },
  content: {
    overflowY: 'hidden !important',
  },
  contentSubtitle: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'ibm-plex-sans-regular',
    color: theme.palette.text.primary,
  },
}))

const FloatingPlanInfo = ({ isNewUi = false, setSelectedPlan, selectedPlan, floatingPlans, camera }) => {
  const theme = useTheme()
  const classes = useStyles({ isNewUi })
  const { t } = useTranslation()
  const breakpoint = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <div>
      { selectedPlan && selectedPlan?.id !== '0' && (

        <div style={{ width: '420px', padding: '20px', backgroundColor: '#1F1E1E' }}><div className={classes.title}>{ selectedPlan?.name }</div>
          <div style={{
            display: 'flex',
            height: '100%',
            marginTop: 15,
            justifyContent: 'space-between',
          }}
          >
            <div style={{
              display: 'flex',
              height: 'auto',
            }}
            >
              <div>{ t('device') }</div>
            </div>
            <div className={classes.contentSubtitle}>{ camera?.config?.name }</div>
          </div>
          <div style={{
            display: 'flex',
            height: '100%',
            marginTop: 15,
            justifyContent: 'space-between',
          }}
          >
            <div style={{
              display: 'flex',
              height: 'auto',
            }}
            >
              <div>{ t('billing.bill.payment_frequency') }</div>
            </div>
            <div className={classes.contentSubtitle}>{ selectedPlan?.frequency === 'month' ? t('app:checkout_process.photo_transmission_plan.monthly') : t('app:checkout_process.photo_transmission_plan.monthly') }</div>
          </div>
          <div style={{
            display: 'flex',
            height: '100%',
            marginTop: 15,
            marginBottom: 15,
            justifyContent: 'space-between',
          }}
          >
            <div style={{
              display: 'flex',
              height: 'auto',
            }}
            >
              <div>{ t('billing.photos_per_month') }</div>
            </div>
            <div className={classes.contentSubtitle}>{ selectedPlan?.name === 'Premium' ? t('billing.bill.Unlimited') : selectedPlan?.capabilities?.photoLimit }</div>
          </div>
          <div style={{
            textAlign: 'left',
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: 'ibm-plex-sans-regular',
            color: '#B1AFAF',
            marginRight: '10px',
            marginBottom: 15,
          }}
          >
            <Divider style={{ marginBottom: '10px' }} />
            <span style={{
              textAlign: 'left',
              fontSize: '12px',
              fontWeight: 400,
              fontFamily: 'ibm-plex-sans-regular',
              color: '#FBF9F9',
              marginBottom: 15,
            }}
            >{ t('floating_plans.note') }
            </span>{ t('floating_plans.footer') }
          </div>
          { breakpoint
            ? (
              <Grid container justifyContent="flex-end">
                <Grid xs={12} item>
                  <FloatingPlansButton isActivation camera={camera} isNewUi={isNewUi} selectedPlan={selectedPlan} />
                </Grid>
              </Grid>
              )
            : (
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <FloatingPlansButton isActivation camera={camera} isNewUi={isNewUi} selectedPlan={selectedPlan} />
                </Grid>
              </Grid>
              ) }
        </div>
      ) }
    </div>
  )
}

export default FloatingPlanInfo
